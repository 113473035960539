import React from 'react';
import dynamic from 'next/dynamic';
import CustomHead from '../components/common/head/Head';

// const HeroArea = dynamic(() => import('../components/pages/homepage/HeroArea'), {
//     loading: () => {
//         return ""
//     }, ssr: false
// });

const HomePage = () => {
    return (<>
        <CustomHead />
        <div className="page-area homepage-area mh-80v">
            {/* app */}
            <div className="art-app art-app-onepage">

                {/* mobile top bar */}
                <div className="art-mobile-top-bar"></div>

                {/* app wrapper */}
                <div className="art-app-wrapper">

                    {/* app container end */}
                    <div className="art-app-container">

                        {/* info bar */}
                        <div className="art-info-bar">

                            {/* menu bar frame */}
                            <div className="art-info-bar-frame">

                                {/* info bar header */}
                                <div className="art-info-bar-header">
                                    {/* info bar button */}
                                    <a className="art-info-bar-btn" href="#.">
                                        {/* icon */}
                                        <i className="fas fa-ellipsis-v"></i>
                                    </a>
                                    {/* info bar button end */}
                                </div>
                                {/* info bar header end */}

                                {/* info bar header */}
                                <div className="art-header">
                                    {/* avatar */}
                                    <div className="art-avatar">
                                        <a data-fancybox="avatar" href="img/face-1.jpg" className="art-avatar-curtain">
                                            <img src="img/face-1.jpg" alt="avatar" />
                                            <i className="fas fa-expand"></i>
                                        </a>
                                        {/* available */}
                                        <div className="art-lamp-light">
                                            {/* add class 'art-not-available' if not available*/}
                                            <div className="art-available-lamp"></div>
                                        </div>
                                    </div>
                                    {/* avatar end */}
                                    {/* name */}
                                    <h5 className="art-name mb-10">Rafiul Islam</h5>
                                    {/* post */}
                                    <div className="art-sm-text">Front-end Deweloper <br />Ui/UX Designer, </div>
                                </div>
                                {/* info bar header end */}

                                {/* scroll frame */}
                                <div id="scrollbar2" className="art-scroll-frame">

                                    {/* info bar about */}
                                    <div className="art-table p-15-15">
                                        {/* about text */}
                                        <ul>
                                            {/* country */}
                                            <li>
                                                <h6>Residence:</h6><span>Canada</span>
                                            </li>
                                            {/* city */}
                                            <li>
                                                <h6>City:</h6><span>Toronto</span>
                                            </li>
                                            {/* age */}
                                            <li>
                                                <h6>Age:</h6><span>26</span>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* info bar about end */}

                                    {/* divider */}
                                    <div className="art-ls-divider"></div>

                                    {/* language skills */}
                                    <div className="art-lang-skills p-30-15">

                                        {/* skill */}
                                        <div className="art-lang-skills-item">
                                            <div id="circleprog1" className="art-cirkle-progress"></div>
                                            {/* title */}
                                            <h6>French</h6>
                                        </div>
                                        {/* skill end */}

                                        {/* skill */}
                                        <div className="art-lang-skills-item">
                                            <div id="circleprog2" className="art-cirkle-progress"></div>
                                            {/* title */}
                                            <h6>English</h6>
                                        </div>
                                        {/* skill end */}

                                        {/* skill */}
                                        <div className="art-lang-skills-item">
                                            <div id="circleprog3" className="art-cirkle-progress"></div>
                                            {/* title */}
                                            <h6>Spanish</h6>
                                        </div>
                                        {/* skill end */}

                                    </div>
                                    {/* language skills end */}

                                    {/* divider */}
                                    <div className="art-ls-divider"></div>

                                    {/* hard skills */}
                                    <div className="art-hard-skills p-30-15">

                                        {/* skill */}
                                        <div className="art-hard-skills-item">
                                            <div className="art-skill-heading">
                                                {/* title */}
                                                <h6>html</h6>
                                            </div>
                                            {/* progressbar frame */}
                                            <div className="art-line-progress">
                                                {/* progressbar */}
                                                <div id="lineprog1"></div>
                                            </div>
                                            {/* progressbar frame end */}
                                        </div>
                                        {/* skill end */}

                                        {/* skill */}
                                        <div className="art-hard-skills-item">
                                            <div className="art-skill-heading">
                                                {/* title */}
                                                <h6>CSS</h6>
                                            </div>
                                            {/* progressbar frame */}
                                            <div className="art-line-progress">
                                                {/* progressbar */}
                                                <div id="lineprog2"></div>
                                            </div>
                                            {/* progressbar frame end */}
                                        </div>
                                        {/* skill end */}

                                        {/* skill */}
                                        <div className="art-hard-skills-item">
                                            <div className="art-skill-heading">
                                                {/* title */}
                                                <h6>Js</h6>
                                            </div>
                                            {/* progressbar frame */}
                                            <div className="art-line-progress">
                                                {/* progressbar */}
                                                <div id="lineprog3"></div>
                                            </div>
                                            {/* progressbar frame end */}
                                        </div>
                                        {/* skill end */}

                                        {/* skill */}
                                        <div className="art-hard-skills-item">
                                            <div className="art-skill-heading">
                                                {/* title */}
                                                <h6>PHP</h6>
                                            </div>
                                            {/* progressbar frame */}
                                            <div className="art-line-progress">
                                                {/* progressbar */}
                                                <div id="lineprog4"></div>
                                            </div>
                                            {/* progressbar frame end */}
                                        </div>
                                        {/* skill end */}

                                        {/* skill */}
                                        <div className="art-hard-skills-item">
                                            <div className="art-skill-heading">
                                                {/* title */}
                                                <h6>Wordpress</h6>
                                            </div>
                                            {/* progressbar frame */}
                                            <div className="art-line-progress">
                                                {/* progressbar */}
                                                <div id="lineprog5"></div>
                                            </div>
                                            {/* progressbar frame end */}
                                        </div>
                                        {/* skill end */}

                                    </div>
                                    {/* language skills end */}

                                    {/* divider */}
                                    <div className="art-ls-divider"></div>

                                    {/* knowledge list */}
                                    <ul className="art-knowledge-list p-15-0">
                                        {/* list item */}
                                        <li>Bootstrap, Materialize</li>
                                        {/* list item */}
                                        <li>Stylus, Sass, Less</li>
                                        {/* list item */}
                                        <li>Gulp, Webpack, Grunt</li>
                                        {/* list item */}
                                        <li>GIT knowledge</li>
                                    </ul>
                                    {/* knowledge list end */}

                                    {/* divider */}
                                    <div className="art-ls-divider"></div>

                                    {/* links frame */}
                                    <div className="art-links-frame p-15-15">

                                        {/* download cv button */}
                                        <a href="files/cv.txt" className="art-link" download>Download cv <i className="fas fa-download"></i></a>

                                    </div>
                                    {/* links frame end */}

                                </div>
                                {/* scroll frame end */}

                                {/* sidebar social */}
                                <div className="art-ls-social">
                                    {/* social link */}
                                    <a href="#." target="_blank"><i className="far fa-circle"></i></a>
                                    {/* social link */}
                                    <a href="#." target="_blank"><i className="far fa-circle"></i></a>
                                    {/* social link */}
                                    <a href="#." target="_blank"><i className="far fa-circle"></i></a>
                                    {/* social link */}
                                    <a href="#." target="_blank"><i className="far fa-circle"></i></a>
                                    {/* social link */}
                                    <a href="#." target="_blank"><i className="far fa-circle"></i></a>
                                </div>
                                {/* sidebar social end */}

                            </div>
                            {/* menu bar frame end */}

                        </div>
                        {/* info bar end */}

                        {/* content */}
                        <div className="art-content">

                            {/* curtain */}
                            <div className="art-curtain"></div>

                            {/* top background */}
                            <div className="art-top-bg" style={{backgroundImage: "url('img/bg.jpg')"}}>
                                {/* overlay */}
                                <div className="art-top-bg-overlay"></div>
                                {/* overlay end */}
                            </div>
                            {/* top background end */}

                            {/* swup container */}
                            <div className="transition-fade" id="swup">

                                {/* scroll frame */}
                                <div id="scrollbar" className="art-scroll-frame">

                                    {/* container */}
                                    <div className="container-fluid">

                                        {/* row */}
                                        <div className="row p-60-0 p-lg-30-0 p-md-15-0">
                                            {/* col */}
                                            <div className="col-lg-12">

                                                {/* banner */}
                                                <div className="art-a art-banner" style={{backgroundImage: "url('img/bg.jpg')"}}>
                                                    {/* banner back */}
                                                    <div className="art-banner-back"></div>
                                                    {/* banner dec */}
                                                    <div className="art-banner-dec"></div>
                                                    {/* banner overlay */}
                                                    <div className="art-banner-overlay">
                                                        {/* main title */}
                                                        <div className="art-banner-title">
                                                            {/* title */}
                                                            <h1 className="mb-15">Discover my Amazing <br />Art Space!</h1>
                                                            {/* suptitle */}
                                                            <div className="art-lg-text art-code mb-25">&lt;<i>code</i>&gt; I build <span className="txt-rotate" data-period="2000"
                                                                data-rotate='[ "web interfaces.", "ios and android applications.", "design mocups.", "automation tools." ]'></span>&lt;/<i>code</i>&gt;</div>
                                                            <div className="art-buttons-frame">
                                                                {/* button */}
                                                                <a href="#." className="art-btn art-btn-md"><span>Explore now</span></a>
                                                                {/* button */}
                                                                <a href="#." className="art-link art-white-link art-w-chevron">Hire me</a>
                                                            </div>
                                                        </div>
                                                        {/* main title end */}
                                                        {/* photo */}
                                                        <img src="img/face-2.png" className="art-banner-photo" alt="Your Name" />
                                                    </div>
                                                    {/* banner overlay end */}
                                                </div>
                                                {/* banner end */}

                                            </div>
                                            {/* col end */}
                                        </div>
                                        {/* row end */}

                                    </div>
                                    {/* container end */}

                                    {/* container */}
                                    <div className="container-fluid">

                                        {/* row */}
                                        <div className="row p-30-0">

                                            {/* col */}
                                            <div className="col-md-3 col-6">

                                                {/* couner frame */}
                                                <div className="art-counter-frame">
                                                    {/* counter */}
                                                    <div className="art-counter-box">
                                                        {/* counter number */}
                                                        <span className="art-counter">10</span><span className="art-counter-plus">+</span>
                                                    </div>
                                                    {/* counter end */}
                                                    {/* title */}
                                                    <h6>Years Experience</h6>
                                                </div>
                                                {/* couner frame end */}

                                            </div>
                                            {/* col end */}

                                            {/* col */}
                                            <div className="col-md-3 col-6">

                                                {/* couner frame */}
                                                <div className="art-counter-frame">
                                                    {/* counter */}
                                                    <div className="art-counter-box">
                                                        {/* counter number */}
                                                        <span className="art-counter">143</span>
                                                    </div>
                                                    {/* counter end */}
                                                    {/* title */}
                                                    <h6>Completed Projects</h6>
                                                </div>
                                                {/* couner frame end */}

                                            </div>
                                            {/* col end */}

                                            {/* col */}
                                            <div className="col-md-3 col-6">

                                                {/* couner frame */}
                                                <div className="art-counter-frame">
                                                    {/* counter */}
                                                    <div className="art-counter-box">
                                                        {/* counter number */}
                                                        <span className="art-counter">114</span>
                                                    </div>
                                                    {/* counter end */}
                                                    {/* title */}
                                                    <h6>Happy Customers</h6>
                                                </div>
                                                {/* couner frame end */}

                                            </div>
                                            {/* col end */}

                                            {/* col */}
                                            <div className="col-md-3 col-6">

                                                {/* couner frame */}
                                                <div className="art-counter-frame">
                                                    {/* counter */}
                                                    <div className="art-counter-box">
                                                        {/* counter number */}
                                                        <span className="art-counter">20</span><span className="art-counter-plus">+</span>
                                                    </div>
                                                    {/* counter end */}
                                                    {/* title */}
                                                    <h6>Honors and Awards</h6>
                                                </div>
                                                {/* couner frame end */}

                                            </div>
                                            {/* col end */}

                                        </div>
                                        {/* row end */}

                                    </div>
                                    {/* container end */}

                                    {/* container */}
                                    <div className="container-fluid">

                                        {/* row */}
                                        <div className="row">

                                            {/* col */}
                                            <div className="col-lg-12">

                                                {/* section title */}
                                                <div className="art-section-title">
                                                    {/* title frame */}
                                                    <div className="art-title-frame">
                                                        {/* title */}
                                                        <h4>My Services</h4>
                                                    </div>
                                                    {/* title frame end */}
                                                </div>
                                                {/* section title end */}

                                            </div>
                                            {/* col end */}

                                            {/* col */}
                                            <div className="col-lg-4 col-md-6">

                                                {/* service */}
                                                <div className="art-a art-service-icon-box">
                                                    {/* service content */}
                                                    <div className="art-service-ib-content">
                                                        {/* title */}
                                                        <h5 className="mb-15">Web Development</h5>
                                                        {/* text */}
                                                        <div className="mb-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus esse commodi deserunt vitae, vero quasi! Veniam quaerat tenetur pariatur doloribus.</div>
                                                        {/* button */}
                                                        <div className="art-buttons-frame"><a href="#." className="art-link art-color-link art-w-chevron">Order now</a></div>
                                                    </div>
                                                    {/* service content end */}
                                                </div>
                                                {/* service end */}

                                            </div>
                                            {/* col end */}

                                            {/* col */}
                                            <div className="col-lg-4 col-md-6">

                                                {/* service */}
                                                <div className="art-a art-service-icon-box">
                                                    {/* service content */}
                                                    <div className="art-service-ib-content">
                                                        {/* title */}
                                                        <h5 className="mb-15">UI/UX Design</h5>
                                                        {/* text */}
                                                        <div className="mb-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus esse commodi deserunt vitae, vero quasi! Veniam quaerat tenetur pariatur doloribus.</div>
                                                        {/* button */}
                                                        <div className="art-buttons-frame"><a href="#." className="art-link art-color-link art-w-chevron">Order now</a></div>
                                                    </div>
                                                    {/* service content end */}
                                                </div>
                                                {/* service end */}

                                            </div>
                                            {/* col end */}

                                            {/* col */}
                                            <div className="col-lg-4 col-md-6">

                                                {/* service */}
                                                <div className="art-a art-service-icon-box">
                                                    {/* service content */}
                                                    <div className="art-service-ib-content">
                                                        {/* title */}
                                                        <h5 className="mb-15">Sound Design</h5>
                                                        {/* text */}
                                                        <div className="mb-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus esse commodi deserunt vitae, vero quasi! Veniam quaerat tenetur pariatur doloribus.</div>
                                                        {/* button */}
                                                        <div className="art-buttons-frame"><a href="#." className="art-link art-color-link art-w-chevron">Order now</a></div>
                                                    </div>
                                                    {/* service content end */}
                                                </div>
                                                {/* service end */}

                                            </div>
                                            {/* col end */}

                                            {/* col */}
                                            <div className="col-lg-4 col-md-6">

                                                {/* service */}
                                                <div className="art-a art-service-icon-box">
                                                    {/* service content */}
                                                    <div className="art-service-ib-content">
                                                        {/* title */}
                                                        <h5 className="mb-15">Game Design</h5>
                                                        {/* text */}
                                                        <div className="mb-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus esse commodi deserunt vitae, vero quasi! Veniam quaerat tenetur pariatur doloribus.</div>
                                                        {/* button */}
                                                        <div className="art-buttons-frame"><a href="#." className="art-link art-color-link art-w-chevron">Order now</a></div>
                                                    </div>
                                                    {/* service content end */}
                                                </div>
                                                {/* service end */}

                                            </div>
                                            {/* col end */}

                                            {/* col */}
                                            <div className="col-lg-4 col-md-6">

                                                {/* service */}
                                                <div className="art-a art-service-icon-box">
                                                    {/* service content */}
                                                    <div className="art-service-ib-content">
                                                        {/* title */}
                                                        <h5 className="mb-15">Advertising</h5>
                                                        {/* text */}
                                                        <div className="mb-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus esse commodi deserunt vitae, vero quasi! Veniam quaerat tenetur pariatur doloribus.</div>
                                                        {/* button */}
                                                        <div className="art-buttons-frame"><a href="#." className="art-link art-color-link art-w-chevron">Order now</a></div>
                                                    </div>
                                                    {/* service content end */}
                                                </div>
                                                {/* service end */}

                                            </div>
                                            {/* col end */}

                                        </div>
                                        {/* row end */}

                                    </div>
                                    {/* container end */}

                                    {/* container */}
                                    <div className="container-fluid">

                                        {/* row */}
                                        <div className="row p-0-0">

                                            {/* col */}
                                            <div className="col-lg-12">

                                                {/* section title */}
                                                <div className="art-section-title">
                                                    {/* title frame */}
                                                    <div className="art-title-frame">
                                                        {/* title */}
                                                        <h4>Price Plans</h4>
                                                    </div>
                                                    {/* title frame end */}
                                                </div>
                                                {/* section title end */}

                                            </div>
                                            {/* col end */}

                                            {/* col */}
                                            <div className="col-lg-4">

                                                {/* price */}
                                                <div className="art-a art-price">
                                                    {/* price body */}
                                                    <div className="art-price-body">
                                                        <h5 className="mb-30">Starter Price</h5>
                                                        {/* price cost */}
                                                        <div className="art-price-cost">
                                                            <div className="art-number">FREE<sup>*</sup></div>
                                                        </div>
                                                        {/* price cost end */}
                                                        {/* price list */}
                                                        <ul className="art-price-list">
                                                            {/* list item */}
                                                            <li>Ui Design</li>
                                                            {/* list item */}
                                                            <li>Web Development</li>
                                                            {/* list item */}
                                                            <li className="art-empty-item">Logo design</li>
                                                            {/* list item */}
                                                            <li className="art-empty-item">SEO optimization</li>
                                                            {/* list item */}
                                                            <li className="art-empty-item">Wordpress integration</li>
                                                        </ul>
                                                        {/* price list end */}
                                                        {/* button */}
                                                        <a href="#." className="art-link art-color-link art-w-chevron">Order now</a>
                                                        <div className="art-asterisk"><sup>*</sup>Free only when ordering paid services</div>
                                                    </div>
                                                    {/* price body end */}
                                                </div>
                                                {/* price end */}

                                            </div>
                                            {/* grid */}

                                            {/* col */}
                                            <div className="col-lg-4">

                                                {/* price */}
                                                <div className="art-a art-price art-popular-price">
                                                    {/* price body */}
                                                    <div className="art-price-body">
                                                        <h5 className="mb-30">Hourly payment</h5>
                                                        {/* price cost */}
                                                        <div className="art-price-cost">
                                                            <div className="art-number"><span>$</span>29<span>h</span></div>
                                                        </div>
                                                        {/* price cost end */}
                                                        {/* price list */}
                                                        <ul className="art-price-list">
                                                            {/* list item */}
                                                            <li>Ui Design</li>
                                                            {/* list item */}
                                                            <li>Web Development</li>
                                                            {/* list item */}
                                                            <li>Logo design</li>
                                                            {/* list item */}
                                                            <li className="art-empty-item">SEO optimization</li>
                                                            {/* list item */}
                                                            <li className="art-empty-item">Wordpress integration</li>
                                                        </ul>
                                                        {/* price list end */}
                                                        {/* button */}
                                                        <a href="#." className="art-link art-color-link art-w-chevron">Order now</a>
                                                    </div>
                                                    {/* price body end */}
                                                </div>
                                                {/* price end */}

                                            </div>
                                            {/* col end */}

                                            {/* col */}
                                            <div className="col-lg-4">

                                                {/* price */}
                                                <div className="art-a art-price">
                                                    {/* price body */}
                                                    <div className="art-price-body">
                                                        <h5 className="mb-30"> Full time</h5>
                                                        {/* price cost */}
                                                        <div className="art-price-cost">
                                                            <div className="art-number"><span>$</span>2999<span>m</span></div>
                                                        </div>
                                                        {/* price cost end */}
                                                        {/* price list */}
                                                        <ul className="art-price-list">
                                                            {/* list item */}
                                                            <li>Ui Design</li>
                                                            {/* list item */}
                                                            <li>Web Development</li>
                                                            {/* list item */}
                                                            <li>Logo design</li>
                                                            {/* list item */}
                                                            <li>SEO optimization</li>
                                                            {/* list item */}
                                                            <li>Wordpress integration</li>
                                                        </ul>
                                                        {/* price list end */}
                                                        {/* button */}
                                                        <a href="#." className="art-link art-color-link art-w-chevron">Order now</a>
                                                    </div>
                                                    {/* price body end */}
                                                </div>
                                                {/* price end */}

                                            </div>
                                            {/* col end */}

                                        </div>
                                        {/* row end */}

                                    </div>
                                    {/* container end */}

                                    {/* container */}
                                    <div className="container-fluid">

                                        {/* row */}
                                        <div className="row">

                                            {/* col */}
                                            <div className="col-lg-12">

                                                {/* section title */}
                                                <div className="art-section-title">
                                                    {/* title frame */}
                                                    <div className="art-title-frame">
                                                        {/* title */}
                                                        <h4>Recommendations</h4>
                                                    </div>
                                                    {/* title frame end */}
                                                </div>
                                                {/* section title end */}

                                            </div>
                                            {/* col end */}

                                            {/* col */}
                                            <div className="col-lg-12">

                                                {/* slider container */}
                                                <div className="swiper-container art-testimonial-slider" style={{overflow: 'visible'}}>
                                                    {/* slider wrapper */}
                                                    <div className="swiper-wrapper">
                                                        {/* slide */}
                                                        <div className="swiper-slide">

                                                            {/* testimonial */}
                                                            <div className="art-a art-testimonial">
                                                                {/* testimonial body */}
                                                                <div className="testimonial-body">
                                                                    {/* photo */}
                                                                    <img className="art-testimonial-face" src="img/testimonials/face-1.jpg" alt="face" />
                                                                    {/* name */}
                                                                    <h5>Paul Trueman</h5>
                                                                    <div className="art-el-suptitle mb-15">Template author</div>
                                                                    {/* text */}
                                                                    <div className="mb-15">Working with Artur has been a pleasure. Better yet - I alerted them of a minor issue before going to sleep. The issue was fixed the next morning. I couldn&apos;t ask for better support. Thank you Artur!
                                                                        This is easily a 5 star freelancer.</div>
                                                                </div>
                                                                {/* testimonial body end */}
                                                                {/* testimonial footer */}
                                                                <div className="art-testimonial-footer">
                                                                    <div className="art-left-side">
                                                                        {/* star rate */}
                                                                        <ul className="art-star-rate">
                                                                            <li><i className="fas fa-star"></i></li>
                                                                            <li><i className="fas fa-star"></i></li>
                                                                            <li><i className="fas fa-star"></i></li>
                                                                            <li><i className="fas fa-star"></i></li>
                                                                            <li><i className="fas fa-star"></i></li>
                                                                        </ul>
                                                                        {/* star rate end */}
                                                                    </div>
                                                                    <div className="art-right-side">

                                                                    </div>
                                                                </div>
                                                                {/* testimonial footer end */}
                                                            </div>
                                                            {/* testimonial end */}

                                                        </div>
                                                        {/* slide end */}

                                                        {/* slide */}
                                                        <div className="swiper-slide">

                                                            {/* testimonial */}
                                                            <div className="art-a art-testimonial">
                                                                {/* testimonial body */}
                                                                <div className="testimonial-body">
                                                                    {/* photo */}
                                                                    <img className="art-testimonial-face" src="img/testimonials/face-2.jpg" alt="face" />
                                                                    {/* name */}
                                                                    <h5>Paul Trueman</h5>
                                                                    <div className="art-el-suptitle mb-15">Template author</div>
                                                                    {/* text */}
                                                                    <div className="mb-15">Working with Artur has been a pleasure. Better yet - I alerted them of a minor issue before going to sleep. The issue was fixed the next morning. I couldn&apos;t ask for better support. Thank you Artur!
                                                                        This is easily a 5 star freelancer.</div>
                                                                </div>
                                                                {/* testimonial body end */}
                                                                {/* testimonial footer */}
                                                                <div className="art-testimonial-footer">
                                                                    <div className="art-left-side">
                                                                        {/* star rate */}
                                                                        <ul className="art-star-rate">
                                                                            <li><i className="fas fa-star"></i></li>
                                                                            <li><i className="fas fa-star"></i></li>
                                                                            <li><i className="fas fa-star"></i></li>
                                                                            <li><i className="fas fa-star"></i></li>
                                                                            <li className="art-empty-item"><i className="fas fa-star"></i></li>
                                                                        </ul>
                                                                        {/* star rate end */}
                                                                    </div>
                                                                    <div className="art-right-side">

                                                                    </div>
                                                                </div>
                                                                {/* testimonial footer end */}
                                                            </div>
                                                            {/* testimonial end */}

                                                        </div>
                                                        {/* slide end */}

                                                        {/* slide */}
                                                        <div className="swiper-slide">

                                                            {/* testimonial */}
                                                            <div className="art-a art-testimonial">
                                                                {/* testimonial body */}
                                                                <div className="testimonial-body">
                                                                    {/* photo */}
                                                                    <img className="art-testimonial-face" src="img/testimonials/face-3.jpg" alt="face" />
                                                                    {/* name */}
                                                                    <h5>Paul Trueman</h5>
                                                                    <div className="art-el-suptitle mb-15">Template author</div>
                                                                    {/* text */}
                                                                    <div className="mb-15">Working with Artur has been a pleasure. Better yet - I alerted them of a minor issue before going to sleep. The issue was fixed the next morning. I couldn&apos;t ask for better support. Thank you Artur!
                                                                        This is easily a 5 star freelancer.</div>
                                                                </div>
                                                                {/* testimonial body end */}
                                                                {/* testimonial footer */}
                                                                <div className="art-testimonial-footer">
                                                                    <div className="art-left-side">
                                                                        {/* star rate */}
                                                                        <ul className="art-star-rate">
                                                                            <li><i className="fas fa-star"></i></li>
                                                                            <li><i className="fas fa-star"></i></li>
                                                                            <li><i className="fas fa-star"></i></li>
                                                                            <li><i className="fas fa-star"></i></li>
                                                                            <li><i className="fas fa-star"></i></li>
                                                                        </ul>
                                                                        {/* star rate end */}
                                                                    </div>
                                                                    <div className="art-right-side">

                                                                    </div>
                                                                </div>
                                                                {/* testimonial footer end */}
                                                            </div>
                                                            {/* testimonial end */}

                                                        </div>
                                                        {/* slide end */}

                                                        {/* slide */}
                                                        <div className="swiper-slide">

                                                            {/* testimonial */}
                                                            <div className="art-a art-testimonial">
                                                                {/* testimonial body */}
                                                                <div className="testimonial-body">
                                                                    {/* photo */}
                                                                    <img className="art-testimonial-face" src="img/testimonials/face-4.jpg" alt="face" />
                                                                    {/* name */}
                                                                    <h5>Paul Trueman</h5>
                                                                    <div className="art-el-suptitle mb-15">Template author</div>
                                                                    {/* text */}
                                                                    <div className="mb-15">Working with Artur has been a pleasure. Better yet - I alerted them of a minor issue before going to sleep. The issue was fixed the next morning. I couldn&apos;t ask for better support. Thank you Artur!
                                                                        This is easily a 5 star freelancer.</div>
                                                                </div>
                                                                {/* testimonial body end */}
                                                                {/* testimonial footer */}
                                                                <div className="art-testimonial-footer">
                                                                    <div className="art-left-side">
                                                                        {/* star rate */}
                                                                        <ul className="art-star-rate">
                                                                            <li><i className="fas fa-star"></i></li>
                                                                            <li><i className="fas fa-star"></i></li>
                                                                            <li><i className="fas fa-star"></i></li>
                                                                            <li><i className="fas fa-star"></i></li>
                                                                            <li><i className="fas fa-star"></i></li>
                                                                        </ul>
                                                                        {/* star rate end */}
                                                                    </div>
                                                                    <div className="art-right-side">

                                                                    </div>
                                                                </div>
                                                                {/* testimonial footer end */}
                                                            </div>
                                                            {/* testimonial end */}

                                                        </div>
                                                        {/* slide end */}

                                                    </div>
                                                    {/* slider wrapper end */}
                                                </div>
                                                {/* slider container end */}

                                            </div>
                                            {/* col end */}

                                            {/* col */}
                                            <div className="col-lg-12">

                                                {/* slider navigation */}
                                                <div className="art-slider-navigation">

                                                    {/* left side */}
                                                    <div className="art-sn-left">

                                                        {/* slider pagination */}
                                                        <div className="swiper-pagination"></div>

                                                    </div>
                                                    {/* left side end */}

                                                    {/* right side */}
                                                    <div className="art-sn-right">

                                                        {/* slider navigation */}
                                                        <div className="art-slider-nav-frame">
                                                            {/* prev */}
                                                            <div className="art-slider-nav art-testi-swiper-prev"><i className="fas fa-chevron-left"></i></div>
                                                            {/* next */}
                                                            <div className="art-slider-nav art-testi-swiper-next"><i className="fas fa-chevron-right"></i></div>
                                                        </div>
                                                        {/* slider navigation */}

                                                    </div>
                                                    {/* right side end */}

                                                </div>
                                                {/* slider navigation end */}

                                            </div>
                                            {/* col end */}

                                        </div>
                                        {/* row end */}

                                    </div>
                                    {/* container end */}

                                    {/* container */}
                                    <div className="container-fluid">

                                        {/* row */}
                                        <div className="row p-30-0">

                                            {/* col */}
                                            <div className="col-lg-12">

                                                {/* section title */}
                                                <div className="art-section-title">
                                                    {/* title frame */}
                                                    <div className="art-title-frame">
                                                        {/* title */}
                                                        <h4>Works</h4>
                                                    </div>
                                                    {/* title frame end */}
                                                    {/* right frame */}
                                                    <div className="art-right-frame">
                                                        {/* filter */}
                                                        <div className="art-filter">
                                                            {/* filter link */}
                                                            <a href="#" data-filter="*" className="art-link art-current">All Categories</a>
                                                            {/* filter link */}
                                                            <a href="#" data-filter=".webTemplates" className="art-link">Web Templates</a>
                                                            {/* filter link */}
                                                            <a href="#" data-filter=".logos" className="art-link">Logos</a>
                                                            {/* filter link */}
                                                            <a href="#" data-filter=".drawings" className="art-link">Drawings</a>
                                                            {/* filter link */}
                                                            <a href="#" data-filter=".ui" className="art-link">UI Elements</a>
                                                        </div>
                                                        {/* filter end */}
                                                    </div>
                                                    {/* right frame end */}
                                                </div>
                                                {/* section title end */}

                                            </div>
                                            {/* col end */}

                                            <div className="art-grid art-grid-3-col art-gallery">

                                                {/* grid item */}
                                                <div className="art-grid-item webTemplates">
                                                    {/* grid item frame */}
                                                    <a data-fancybox="gallery" href="img/works/original-size/1.jpg" className="art-a art-portfolio-item-frame art-horizontal">
                                                        {/* img */}
                                                        <img src="img/works/thumbnail/1.jpg" alt="item" />
                                                        {/* zoom icon */}
                                                        <span className="art-item-hover"><i className="fas fa-expand"></i></span>
                                                    </a>
                                                    {/* grid item frame end */}
                                                    {/* description */}
                                                    <div className="art-item-description">
                                                        {/* title */}
                                                        <h5 className="mb-15">Project title</h5>
                                                        {/* button */}
                                                        <a href="#." className="art-link art-color-link art-w-chevron">Read more</a>
                                                    </div>
                                                    {/* description end */}

                                                </div>
                                                {/* grid item end */}

                                                {/* grid item */}
                                                <div className="art-grid-item logos">
                                                    {/* grid item frame */}
                                                    <a data-fancybox="gallery" href="img/works/original-size/2.jpg" className="art-a art-portfolio-item-frame art-vertical">
                                                        {/* img */}
                                                        <img src="img/works/thumbnail/2.jpg" alt="item" />
                                                        {/* zoom icon */}
                                                        <span className="art-item-hover"><i className="fas fa-expand"></i></span>
                                                    </a>
                                                    {/* grid item frame end */}
                                                    {/* description */}
                                                    <div className="art-item-description">
                                                        {/* title */}
                                                        <h5 className="mb-15">Project title</h5>
                                                        <div className="mb-15">Sit amet, consectetur adipisicing elit. Quas, architecto.</div>
                                                        {/* button */}
                                                        <a href="#." className="art-link art-color-link art-w-chevron">Read more</a>
                                                    </div>
                                                    {/* description end */}

                                                </div>
                                                {/* grid item end */}

                                                {/* grid item */}
                                                <div className="art-grid-item drawings">
                                                    {/* grid item frame */}
                                                    <a data-fancybox="gallery" href="img/works/original-size/3.jpg" className="art-a art-portfolio-item-frame art-square">
                                                        {/* img */}
                                                        <img src="img/works/thumbnail/3.jpg" alt="item" />
                                                        {/* zoom icon */}
                                                        <span className="art-item-hover"><i className="fas fa-expand"></i></span>
                                                    </a>
                                                    {/* grid item frame end */}
                                                    {/* description */}
                                                    <div className="art-item-description">
                                                        {/* title */}
                                                        <h5 className="mb-15">Project title</h5>
                                                        <div className="mb-15">Sit amet, consectetur adipisicing elit. Quas, architecto.</div>
                                                        {/* button */}
                                                        <a href="#." className="art-link art-color-link art-w-chevron">Read more</a>
                                                    </div>
                                                    {/* description end */}

                                                </div>
                                                {/* grid item end */}

                                                {/* grid item */}
                                                <div className="art-grid-item ui">
                                                    {/* grid item frame */}
                                                    <a data-fancybox="gallery" href="img/works/original-size/4.jpg" className="art-a art-portfolio-item-frame art-vertical">
                                                        {/* img */}
                                                        <img src="img/works/thumbnail/4.jpg" alt="item" />
                                                        {/* zoom icon */}
                                                        <span className="art-item-hover"><i className="fas fa-expand"></i></span>
                                                    </a>
                                                    {/* grid item frame end */}
                                                    {/* description */}
                                                    <div className="art-item-description">
                                                        {/* title */}
                                                        <h5 className="mb-15">Project title</h5>
                                                        <div className="mb-15">Sit amet, consectetur adipisicing elit. Quas, architecto.</div>
                                                        {/* button */}
                                                        <a href="#." className="art-link art-color-link art-w-chevron">Read more</a>
                                                    </div>
                                                    {/* description end */}

                                                </div>
                                                {/* grid item end */}

                                                {/* grid item */}
                                                <div className="art-grid-item webTemplates">
                                                    {/* grid item frame */}
                                                    <a data-fancybox="gallery" href="img/works/original-size/5.jpg" className="art-a art-portfolio-item-frame art-square">
                                                        {/* img */}
                                                        <img src="img/works/thumbnail/5.jpg" alt="item" />
                                                        {/* zoom icon */}
                                                        <span className="art-item-hover"><i className="fas fa-expand"></i></span>
                                                    </a>
                                                    {/* grid item frame end */}
                                                    {/* description */}
                                                    <div className="art-item-description">
                                                        {/* title */}
                                                        <h5 className="mb-15">Project title</h5>
                                                        <div className="mb-15">Sit amet, consectetur adipisicing elit. Quas, architecto.</div>
                                                        {/* button */}
                                                        <a href="#." className="art-link art-color-link art-w-chevron">Read more</a>
                                                    </div>
                                                    {/* description end */}

                                                </div>
                                                {/* grid item end */}

                                                {/* grid item */}
                                                <div className="art-grid-item logos">
                                                    {/* grid item frame */}
                                                    <a data-fancybox="gallery" href="img/works/original-size/6.jpg" className="art-a art-portfolio-item-frame art-vertical">
                                                        {/* img */}
                                                        <img src="img/works/thumbnail/6.jpg" alt="item" />
                                                        {/* zoom icon */}
                                                        <span className="art-item-hover"><i className="fas fa-expand"></i></span>
                                                    </a>
                                                    {/* grid item frame end */}
                                                    {/* description */}
                                                    <div className="art-item-description">
                                                        {/* title */}
                                                        <h5 className="mb-15">Project title</h5>
                                                        <div className="mb-15">Sit amet, consectetur adipisicing elit. Quas, architecto.</div>
                                                        {/* button */}
                                                        <a href="#." className="art-link art-color-link art-w-chevron">Read more</a>
                                                    </div>
                                                    {/* description end */}

                                                </div>
                                                {/* grid item end */}

                                                {/* grid item */}
                                                <div className="art-grid-item drawings">
                                                    {/* grid item frame */}
                                                    <a data-fancybox="gallery" href="img/works/original-size/7.jpg" className="art-a art-portfolio-item-frame art-square">
                                                        {/* img */}
                                                        <img src="img/works/thumbnail/7.jpg" alt="item" />
                                                        {/* zoom icon */}
                                                        <span className="art-item-hover"><i className="fas fa-expand"></i></span>
                                                    </a>
                                                    {/* grid item frame end */}
                                                    {/* description */}
                                                    <div className="art-item-description">
                                                        {/* title */}
                                                        <h5 className="mb-15">Project title</h5>
                                                        <div className="mb-15">Sit amet, consectetur adipisicing elit. Quas, architecto.</div>
                                                        {/* button */}
                                                        <a href="#." className="art-link art-color-link art-w-chevron">Read more</a>
                                                    </div>
                                                    {/* description end */}

                                                </div>
                                                {/* grid item end */}

                                                {/* grid item */}
                                                <div className="art-grid-item ui">
                                                    {/* grid item frame */}
                                                    <a data-fancybox="gallery" href="img/works/original-size/8.jpg" className="art-a art-portfolio-item-frame art-horizontal">
                                                        {/* img */}
                                                        <img src="img/works/thumbnail/8.jpg" alt="item" />
                                                        {/* zoom icon */}
                                                        <span className="art-item-hover"><i className="fas fa-expand"></i></span>
                                                    </a>
                                                    {/* grid item frame end */}
                                                    {/* description */}
                                                    <div className="art-item-description">
                                                        {/* title */}
                                                        <h5 className="mb-15">Project title</h5>
                                                        {/* button */}
                                                        <a href="#." className="art-link art-color-link art-w-chevron">Read more</a>
                                                    </div>
                                                    {/* description end */}

                                                </div>
                                                {/* grid item end */}

                                            </div>

                                        </div>
                                        {/* row end */}

                                    </div>
                                    {/* container end */}

                                    {/* container */}
                                    <div className="container-fluid">

                                        {/* row */}
                                        <div className="row">

                                            {/* col */}
                                            <div className="col-lg-6">

                                                {/* section title */}
                                                <div className="art-section-title">
                                                    {/* title frame */}
                                                    <div className="art-title-frame">
                                                        {/* title */}
                                                        <h4>Education</h4>
                                                    </div>
                                                    {/* title frame end */}
                                                </div>
                                                {/* section title end */}

                                                {/* timeline */}
                                                <div className="art-timeline art-gallery" id="history">
                                                    <div className="art-timeline-item">
                                                        <div className="art-timeline-mark-light"></div>
                                                        <div className="art-timeline-mark"></div>

                                                        <div className="art-a art-timeline-content">
                                                            <div className="art-card-header">
                                                                <div className="art-left-side">
                                                                    <h5>Title of section 1</h5>
                                                                    <div className="art-el-suptitle mb-15">Template author</div>
                                                                </div>
                                                                <div className="art-right-side">
                                                                    <span className="art-date">jan 2018 - may 2020</span>
                                                                </div>
                                                            </div>

                                                            <p>Dolor sit amet, consectetur adipisicing elit. Iusto, optio, dolorum provident rerum aut hic quasi placeat iure tempora laudantium ipsa ad debitis unde? Iste voluptatibus minus veritatis qui ut.</p>
                                                            <a data-fancybox="diplome" href="files/certificate.jpg" className="art-link art-color-link art-w-chevron">Diplome</a>
                                                        </div>
                                                    </div>

                                                    <div className="art-timeline-item">
                                                        <div className="art-timeline-mark-light"></div>
                                                        <div className="art-timeline-mark"></div>

                                                        <div className="art-a art-timeline-content">
                                                            <div className="art-card-header">
                                                                <div className="art-left-side">
                                                                    <h5>Title of section 1</h5>
                                                                    <div className="art-el-suptitle mb-15">Template author</div>
                                                                </div>
                                                                <div className="art-right-side">
                                                                    <span className="art-date">jan 2018 - may 2020</span>
                                                                </div>
                                                            </div>
                                                            <div>Consectetur adipisicing elit. Iusto, optio, dolorum provident rerum aut hic quasi placeat iure tempora laudantium ipsa ad debitis unde?</div>
                                                        </div>
                                                    </div>

                                                    <div className="art-timeline-item">
                                                        <div className="art-timeline-mark-light"></div>
                                                        <div className="art-timeline-mark"></div>

                                                        <div className="art-a art-timeline-content">
                                                            <div className="art-card-header">
                                                                <div className="art-left-side">
                                                                    <h5>Title of section 1</h5>
                                                                    <div className="art-el-suptitle mb-15">Template author</div>
                                                                </div>
                                                                <div className="art-right-side">
                                                                    <span className="art-date">jan 2018 - may 2020</span>
                                                                </div>
                                                            </div>
                                                            <p>Dolorum provident rerum aut hic quasi placeat iure tempora laudantium ipsa ad debitis unde? Iste voluptatibus minus veritatis qui ut.</p>
                                                            <a data-fancybox="diplome" href="files/certificate.jpg" className="art-link art-color-link art-w-chevron">Licence</a>
                                                        </div>

                                                    </div>

                                                    <div className="art-timeline-item">
                                                        <div className="art-timeline-mark-light"></div>
                                                        <div className="art-timeline-mark"></div>

                                                        <div className="art-a art-timeline-content">
                                                            <div className="art-card-header">
                                                                <div className="art-left-side">
                                                                    <h5>Title of section 1</h5>
                                                                    <div className="art-el-suptitle mb-15">Template author</div>
                                                                </div>
                                                                <div className="art-right-side">
                                                                    <span className="art-date">jan 2018 - may 2020</span>
                                                                </div>
                                                            </div>
                                                            <p>Ipsum dolor sit amet, consectetur adipisicing elit. Iusto, optio, dolorum provident rerum.</p>
                                                            <a data-fancybox="diplome" href="files/certificate.jpg" className="art-link art-color-link art-w-chevron">Certificate</a>
                                                        </div>

                                                    </div>

                                                </div>
                                                {/* timeline end */}

                                            </div>
                                            <div className="col-lg-6">

                                                {/* section title */}
                                                <div className="art-section-title">
                                                    {/* title frame */}
                                                    <div className="art-title-frame">
                                                        {/* title */}
                                                        <h4>Work History</h4>
                                                    </div>
                                                    {/* title frame end */}
                                                </div>
                                                {/* section title end */}

                                                {/* timeline */}
                                                <div className="art-timeline">

                                                    <div className="art-timeline-item">
                                                        <div className="art-timeline-mark-light"></div>
                                                        <div className="art-timeline-mark"></div>


                                                        <div className="art-a art-timeline-content">
                                                            <div className="art-card-header">
                                                                <div className="art-left-side">
                                                                    <h5>Title of section 1</h5>
                                                                    <div className="art-el-suptitle mb-15">Template author</div>
                                                                </div>
                                                                <div className="art-right-side">
                                                                    <span className="art-date">jan 2018 - may 2020</span>
                                                                </div>
                                                            </div>
                                                            <p>Placeat iure tempora laudantium ipsa ad debitis unde? Iste voluptatibus minus veritatis qui ut.</p>
                                                        </div>
                                                    </div>

                                                    <div className="art-timeline-item">
                                                        <div className="art-timeline-mark-light"></div>
                                                        <div className="art-timeline-mark"></div>


                                                        <div className="art-a art-timeline-content">
                                                            <div className="art-card-header">
                                                                <div className="art-left-side">
                                                                    <h5>Title of section 1</h5>
                                                                    <div className="art-el-suptitle mb-15">Template author</div>
                                                                </div>
                                                                <div className="art-right-side">
                                                                    <span className="art-date">jan 2018 - may 2020</span>
                                                                </div>
                                                            </div>
                                                            <p>Adipisicing elit. Iusto, optio, dolorum provident rerum aut hic quasi placeat iure tempora laudantium ipsa ad debitis unde?</p>
                                                            <a data-fancybox="recommendation" href="#art-recomendation-popup-1" className="art-link art-color-link art-w-chevron">Recommendation</a>
                                                        </div>

                                                        {/* popup */}
                                                        <div className="art-recomendation-popup" style={{display: 'none'}} id="art-recomendation-popup-1">

                                                            {/* testimonial */}
                                                            <div className="art-a art-testimonial">
                                                                {/* testimonial body */}
                                                                <div className="testimonial-body">
                                                                    {/* photo */}
                                                                    <img className="art-testimonial-face" src="img/testimonials/face-3.jpg" alt="face" />
                                                                    {/* name */}
                                                                    <h5>Paul Trueman</h5>
                                                                    <div className="art-el-suptitle mb-15">Template author</div>
                                                                    {/* text */}
                                                                    <div className="mb-15">Working with Artur has been a pleasure. Better yet - I alerted them of a minor issue before going to sleep. The issue was fixed the next morning. I couldn&apos;t ask for better support. Thank you Artur!
                                                                        This is easily a 5 star freelancer.</div>
                                                                </div>
                                                                {/* testimonial body end */}
                                                                {/* testimonial footer */}
                                                                <div className="art-testimonial-footer">
                                                                    <div className="art-left-side">
                                                                        {/* star rate */}
                                                                        <ul className="art-star-rate">
                                                                            <li><i className="fas fa-star"></i></li>
                                                                            <li><i className="fas fa-star"></i></li>
                                                                            <li><i className="fas fa-star"></i></li>
                                                                            <li><i className="fas fa-star"></i></li>
                                                                            <li><i className="fas fa-star"></i></li>
                                                                        </ul>
                                                                        {/* star rate end */}
                                                                    </div>
                                                                    <div className="art-right-side">

                                                                    </div>
                                                                </div>
                                                                {/* testimonial footer end */}
                                                            </div>
                                                            {/* testimonial end */}

                                                        </div>
                                                        {/* popup end */}

                                                    </div>

                                                    <div className="art-timeline-item">
                                                        <div className="art-timeline-mark-light"></div>
                                                        <div className="art-timeline-mark"></div>

                                                        <div className="art-a art-timeline-content">
                                                            <div className="art-card-header">
                                                                <div className="art-left-side">
                                                                    <h5>Title of section 1</h5>
                                                                    <div className="art-el-suptitle mb-15">Template author</div>
                                                                </div>
                                                                <div className="art-right-side">
                                                                    <span className="art-date">jan 2018 - may 2020</span>
                                                                </div>
                                                            </div>
                                                            <p>Consectetur adipisicing elit. Excepturi, obcaecati, quisquam id molestias eaque asperiores voluptatibus cupiditate error assumenda delectus odit similique earum voluptatem doloremque dolorem
                                                                ipsam quae rerum quis. Odit, itaque, deserunt corporis vero ipsum nisi eius odio natus ullam provident pariatur temporibus quia eos repellat consequuntur perferendis enim amet quae quasi repudiandae sed quod veniam
                                                                dolore
                                                                possimus rem voluptatum eveniet eligendi quis fugiat aliquam sunt similique aut adipisci.</p>
                                                            <a data-fancybox="recommendation" href="#art-recomendation-popup-2" className="art-link art-color-link art-w-chevron">Recommendation</a>
                                                        </div>

                                                        {/* popup */}
                                                        <div className="art-recomendation-popup" style={{display: 'none'}} id="art-recomendation-popup-2">

                                                            {/* testimonial */}
                                                            <div className="art-a art-testimonial">
                                                                {/* testimonial body */}
                                                                <div className="testimonial-body">
                                                                    {/* photo */}
                                                                    <img className="art-testimonial-face" src="img/testimonials/face-4.jpg" alt="face" />
                                                                    {/* name */}
                                                                    <h5>Paul Trueman</h5>
                                                                    <div className="art-el-suptitle mb-15">Template author</div>
                                                                    {/* text */}
                                                                    <div className="mb-15">Working with Artur has been a pleasure. Better yet - I alerted them of a minor issue before going to sleep. The issue was fixed the next morning. I couldn&apos;t ask for better support. Thank you Artur!
                                                                        This is easily a 5 star freelancer.</div>
                                                                </div>
                                                                {/* testimonial body end */}
                                                                {/* testimonial footer */}
                                                                <div className="art-testimonial-footer">
                                                                    <div className="art-left-side">
                                                                        {/* star rate */}
                                                                        <ul className="art-star-rate">
                                                                            <li><i className="fas fa-star"></i></li>
                                                                            <li><i className="fas fa-star"></i></li>
                                                                            <li><i className="fas fa-star"></i></li>
                                                                            <li><i className="fas fa-star"></i></li>
                                                                            <li><i className="fas fa-star"></i></li>
                                                                        </ul>
                                                                        {/* star rate end */}
                                                                    </div>
                                                                    <div className="art-right-side">

                                                                    </div>
                                                                </div>
                                                                {/* testimonial footer end */}
                                                            </div>
                                                            {/* testimonial end */}

                                                        </div>
                                                        {/* popup end */}

                                                    </div>

                                                    <div className="art-timeline-item">
                                                        <div className="art-timeline-mark-light"></div>
                                                        <div className="art-timeline-mark"></div>

                                                        <div className="art-a art-timeline-content">
                                                            <div className="art-card-header">
                                                                <div className="art-left-side">
                                                                    <h5>Title of section 1</h5>
                                                                    <div className="art-el-suptitle mb-15">Template author</div>
                                                                </div>
                                                                <div className="art-right-side">
                                                                    <span className="art-date">jan 2018 - tonight</span>
                                                                </div>
                                                            </div>
                                                            <p>Dolor sit amet, consectetur adipisicing elit. Iusto, optio, dolorum provident rerum.</p>
                                                            <a data-fancybox="recommendation" href="#art-recomendation-popup-3" className="art-link art-color-link art-w-chevron">Recommendation</a>
                                                        </div>

                                                        {/* popup */}
                                                        <div className="art-recomendation-popup" style={{display: 'none'}} id="art-recomendation-popup-3">

                                                            {/* testimonial */}
                                                            <div className="art-a art-testimonial">
                                                                {/* testimonial body */}
                                                                <div className="testimonial-body">
                                                                    {/* photo */}
                                                                    <img className="art-testimonial-face" src="img/testimonials/face-2.jpg" alt="face" />
                                                                    {/* name */}
                                                                    <h5>Paul Trueman</h5>
                                                                    <div className="art-el-suptitle mb-15">Template author</div>
                                                                    {/* text */}
                                                                    <div className="mb-15">Working with Artur has been a pleasure. Better yet - I alerted them of a minor issue before going to sleep. The issue was fixed the next morning. I couldn&apos;t ask for better support. Thank you Artur!
                                                                        This is easily a 5 star freelancer.</div>
                                                                </div>
                                                                {/* testimonial body end */}
                                                                {/* testimonial footer */}
                                                                <div className="art-testimonial-footer">
                                                                    <div className="art-left-side">
                                                                        {/* star rate */}
                                                                        <ul className="art-star-rate">
                                                                            <li><i className="fas fa-star"></i></li>
                                                                            <li><i className="fas fa-star"></i></li>
                                                                            <li><i className="fas fa-star"></i></li>
                                                                            <li><i className="fas fa-star"></i></li>
                                                                            <li className="art-empty-item"><i className="fas fa-star"></i></li>
                                                                        </ul>
                                                                        {/* star rate end */}
                                                                    </div>
                                                                    <div className="art-right-side">

                                                                    </div>
                                                                </div>
                                                                {/* testimonial footer end */}
                                                            </div>
                                                            {/* testimonial end */}

                                                        </div>
                                                        {/* popup end */}

                                                    </div>

                                                </div>
                                                {/* timeline end */}

                                            </div>
                                            {/* col end */}

                                        </div>
                                        {/* row end */}

                                    </div>
                                    {/* container end */}

                                    {/* container */}
                                    <div className="container-fluid">

                                        {/* row */}
                                        <div className="row">

                                            {/* col */}
                                            <div className="col-lg-12">

                                                {/* section title */}
                                                <div className="art-section-title">
                                                    {/* title frame */}
                                                    <div className="art-title-frame">
                                                        {/* title */}
                                                        <h4>Newsletter</h4>
                                                    </div>
                                                    {/* title frame end */}
                                                </div>
                                                {/* section title end */}

                                            </div>
                                            {/* col end */}

                                            {/* col */}
                                            <div className="col-lg-12">

                                                {/* slider container */}
                                                <div className="swiper-container art-blog-slider" style={{overflow: 'visible'}}>
                                                    {/* slider wrapper */}
                                                    <div className="swiper-wrapper">
                                                        {/* slide */}
                                                        <div className="swiper-slide">

                                                            {/* blog post card */}
                                                            <div className="art-a art-blog-card">
                                                                {/* post cover */}
                                                                <a href="#." className="art-port-cover">
                                                                    {/* img */}
                                                                    <img src="img/blog/1.jpg" alt="blog post" />
                                                                </a>
                                                                {/* post cover end */}
                                                                {/* post description */}
                                                                <div className="art-post-description">
                                                                    {/* title */}
                                                                    <a href="#.">
                                                                        <h5 className="mb-15">Blog post title</h5>
                                                                    </a>
                                                                    {/* text */}
                                                                    <div className="mb-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet!</div>
                                                                    {/* link */}
                                                                    <a href="#." className="art-link art-color-link art-w-chevron">Read more</a>
                                                                </div>
                                                                {/* post description end */}
                                                            </div>
                                                            {/* blog post card end */}

                                                        </div>
                                                        {/* slide end */}
                                                        {/* slide */}
                                                        <div className="swiper-slide">

                                                            {/* blog post card */}
                                                            <div className="art-a art-blog-card">
                                                                {/* post cover */}
                                                                <a href="#." className="art-port-cover">
                                                                    {/* img */}
                                                                    <img src="img/blog/2.jpg" alt="blog post" />
                                                                </a>
                                                                {/* post cover end */}
                                                                {/* post description */}
                                                                <div className="art-post-description">
                                                                    {/* title */}
                                                                    <a href="#.">
                                                                        <h5 className="mb-15">Blog post title</h5>
                                                                    </a>
                                                                    {/* text */}
                                                                    <div className="mb-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet!</div>
                                                                    {/* link */}
                                                                    <a href="#." className="art-link art-color-link art-w-chevron">Read more</a>
                                                                </div>
                                                                {/* post description end */}
                                                            </div>
                                                            {/* blog post card end */}

                                                        </div>
                                                        {/* slide end */}
                                                        {/* slide */}
                                                        <div className="swiper-slide">

                                                            {/* blog post card */}
                                                            <div className="art-a art-blog-card">
                                                                {/* post cover */}
                                                                <a href="#." className="art-port-cover">
                                                                    {/* img */}
                                                                    <img src="img/blog/3.jpg" alt="blog post" />
                                                                </a>
                                                                {/* post cover end */}
                                                                {/* post description */}
                                                                <div className="art-post-description">
                                                                    {/* title */}
                                                                    <a href="#.">
                                                                        <h5 className="mb-15">Blog post title</h5>
                                                                    </a>
                                                                    {/* text */}
                                                                    <div className="mb-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet!</div>
                                                                    {/* link */}
                                                                    <a href="#." className="art-link art-color-link art-w-chevron">Read more</a>
                                                                </div>
                                                                {/* post description end */}
                                                            </div>
                                                            {/* blog post card end */}

                                                        </div>
                                                        {/* slide end */}
                                                        {/* slide */}
                                                        <div className="swiper-slide">

                                                            {/* blog post card */}
                                                            <div className="art-a art-blog-card">
                                                                {/* post cover */}
                                                                <a href="#." className="art-port-cover">
                                                                    {/* img */}
                                                                    <img src="img/blog/4.jpg" alt="blog post" />
                                                                </a>
                                                                {/* post cover end */}
                                                                {/* post description */}
                                                                <div className="art-post-description">
                                                                    {/* title */}
                                                                    <a href="#.">
                                                                        <h5 className="mb-15">Blog post title</h5>
                                                                    </a>
                                                                    {/* text */}
                                                                    <div className="mb-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet!</div>
                                                                    {/* link */}
                                                                    <a href="#." className="art-link art-color-link art-w-chevron">Read more</a>
                                                                </div>
                                                                {/* post description end */}
                                                            </div>
                                                            {/* blog post card end */}

                                                        </div>
                                                        {/* slide end */}
                                                        {/* slide */}
                                                        <div className="swiper-slide">

                                                            {/* blog post card */}
                                                            <div className="art-a art-blog-card">
                                                                {/* post cover */}
                                                                <a href="#." className="art-port-cover">
                                                                    {/* img */}
                                                                    <img src="img/blog/5.jpg" alt="blog post" />
                                                                </a>
                                                                {/* post cover end */}
                                                                {/* post description */}
                                                                <div className="art-post-description">
                                                                    {/* title */}
                                                                    <a href="#.">
                                                                        <h5 className="mb-15">Blog post title</h5>
                                                                    </a>
                                                                    {/* text */}
                                                                    <div className="mb-15">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet!</div>
                                                                    {/* link */}
                                                                    <a href="#." className="art-link art-color-link art-w-chevron">Read more</a>
                                                                </div>
                                                                {/* post description end */}
                                                            </div>
                                                            {/* blog post card end */}

                                                        </div>
                                                        {/* slide end */}
                                                    </div>
                                                    {/* slider wrapper end */}
                                                </div>
                                                {/* slider container end */}

                                            </div>
                                            {/* col end */}

                                            {/* col */}
                                            <div className="col-lg-12">

                                                {/* slider navigation */}
                                                <div className="art-slider-navigation">

                                                    {/* left side */}
                                                    <div className="art-sn-left">

                                                        {/* slider pagination */}
                                                        <div className="swiper-pagination"></div>

                                                    </div>
                                                    {/* left side end */}

                                                    {/* right side */}
                                                    <div className="art-sn-right">

                                                        {/* slider navigation */}
                                                        <div className="art-slider-nav-frame">
                                                            {/* prev */}
                                                            <div className="art-slider-nav art-blog-swiper-prev"><i className="fas fa-chevron-left"></i></div>
                                                            {/* next */}
                                                            <div className="art-slider-nav art-blog-swiper-next"><i className="fas fa-chevron-right"></i></div>
                                                        </div>
                                                        {/* slider navigation */}

                                                    </div>
                                                    {/* right side end */}

                                                </div>
                                                {/* slider navigation end */}

                                            </div>
                                            {/* col end */}

                                        </div>
                                        {/* row end */}

                                    </div>
                                    {/* container end */}

                                    {/* container */}
                                    <div className="container-fluid">

                                        {/* row */}
                                        <div className="row p-30-0">

                                            {/* col */}
                                            <div className="col-lg-12">

                                                {/* section title */}
                                                <div className="art-section-title">
                                                    {/* title frame */}
                                                    <div className="art-title-frame">
                                                        {/* title */}
                                                        <h4>Contact information</h4>
                                                    </div>
                                                    {/* title frame end */}
                                                </div>
                                                {/* section title end */}

                                            </div>
                                            {/* col end */}
                                            {/* col */}
                                            <div className="col-lg-4">
                                                {/* contact card */}
                                                <div className="art-a art-card">
                                                    <div className="art-table p-15-15">
                                                        <ul>
                                                            <li>
                                                                <h6>Country:</h6><span>Canada</span>
                                                            </li>
                                                            <li>
                                                                <h6>City:</h6><span>Toronto</span>
                                                            </li>

                                                            <li>
                                                                <h6>Streat:</h6><span>20 Dellbank Rd</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                {/* contact card end */}
                                            </div>
                                            {/* col end */}
                                            {/* col */}
                                            <div className="col-lg-4">
                                                {/* contact card */}
                                                <div className="art-a art-card">
                                                    <div className="art-table p-15-15">
                                                        <ul>
                                                            <li>
                                                                <h6>Email:</h6><span>carter.inbox@mail.com</span>
                                                            </li>
                                                            <li>
                                                                <h6>Telegram:</h6><span>@arter</span>
                                                            </li>
                                                            <li>
                                                                <h6>Skype:</h6><span>Arter</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                {/* contact card end */}
                                            </div>
                                            {/* col end */}
                                            {/* col */}
                                            <div className="col-lg-4">
                                                {/* contact card */}
                                                <div className="art-a art-card">
                                                    <div className="art-table p-15-15">
                                                        <ul>
                                                            <li>
                                                                <h6>Support service:</h6><span>+78 (098) 333 11 22</span>
                                                            </li>
                                                            <li>
                                                                <h6>Office:</h6><span>+78 (098) 326 11 22</span>
                                                            </li>
                                                            <li>
                                                                <h6>Personal:</h6><span>+78 (077) 114 26 53</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                {/* contact card end */}

                                            </div>
                                            {/* col end */}

                                            {/* col */}
                                            <div className="col-lg-12">

                                                {/* section title */}
                                                <div className="art-section-title">
                                                    {/* title frame */}
                                                    <div className="art-title-frame">
                                                        {/* title */}
                                                        <h4>Get in touch</h4>
                                                    </div>
                                                    {/* title frame end */}
                                                </div>
                                                {/* section title end */}

                                                {/* contact form frame */}
                                                <div className="art-a art-card">

                                                    {/* contact form */}
                                                    <form id="form" className="art-contact-form">
                                                        {/* form field */}
                                                        <div className="art-form-field">
                                                            {/* name input */}
                                                            <input id="name" name="name" className="art-input" type="text" placeholder="Name" required />
                                                            {/* label */}
                                                            <label htmlFor="name"><i className="fas fa-user"></i></label>
                                                        </div>
                                                        {/* form field end */}
                                                        {/* form field */}
                                                        <div className="art-form-field">
                                                            {/* email input */}
                                                            <input id="email" name="email" className="art-input" type="email" placeholder="Email" required />
                                                            {/* label */}
                                                            <label htmlFor="email"><i className="fas fa-at"></i></label>
                                                        </div>
                                                        {/* form field end */}
                                                        {/* form field */}
                                                        <div className="art-form-field">
                                                            {/* message textarea */}
                                                            <textarea id="message" name="text" className="art-input" placeholder="Message" required></textarea>
                                                            {/* label */}
                                                            <label htmlFor="message"><i className="far fa-envelope"></i></label>
                                                        </div>
                                                        {/* form field end */}
                                                        {/* button */}
                                                        <div className="art-submit-frame">
                                                            <button className="art-btn art-btn-md art-submit" type="submit"><span>Send message</span></button>
                                                            {/* success */}
                                                            <div className="art-success">Success <i className="fas fa-check"></i></div>
                                                        </div>
                                                    </form>
                                                    {/* contact form end */}

                                                </div>
                                                {/* contact form frame end */}

                                            </div>
                                            {/* col end */}

                                        </div>
                                        {/* row end */}

                                    </div>
                                    {/* container end */}

                                    {/* container */}
                                    <div className="container-fluid">

                                        {/* row */}
                                        <div className="row">

                                            {/* col */}
                                            <div className="col-6 col-lg-3">
                                                {/* brand */}
                                                <img className="art-brand" src="img/brands/1.png" alt="brand" />
                                            </div>
                                            {/* col end */}

                                            {/* col */}
                                            <div className="col-6 col-lg-3">
                                                {/* brand */}
                                                <img className="art-brand" src="img/brands/2.png" alt="brand" />
                                            </div>
                                            {/* col end */}

                                            {/* col */}
                                            <div className="col-6 col-lg-3">
                                                {/* brand */}
                                                <img className="art-brand" src="img/brands/3.png" alt="brand" />
                                            </div>
                                            {/* col end */}

                                            {/* col */}
                                            <div className="col-6 col-lg-3">
                                                {/* brand */}
                                                <img className="art-brand" src="img/brands/1.png" alt="brand" />
                                            </div>
                                            {/* col end */}

                                        </div>
                                        {/* row end */}

                                    </div>
                                    {/* container end */}

                                    {/* container */}
                                    <div className="container-fluid">

                                        {/* footer */}
                                        <footer>
                                            {/* copyright */}
                                            <div>© 2020 Rafiul Islam</div>
                                        </footer>
                                        {/* footer end */}

                                    </div>
                                    {/* container end */}

                                </div>
                                {/* scroll frame end */}

                            </div>
                            {/* swup container end */}

                        </div>
                        {/* content end */}

                    </div>
                    {/* app container end */}

                </div>
                {/* app wrapper end */}

                {/* preloader */}
                <div className="art-preloader">
                    {/* preloader content */}
                    <div className="art-preloader-content">
                        {/* title */}
                        <h4>Rafiul Islam</h4>
                        {/* progressbar */}
                        <div id="preloader" className="art-preloader-load"></div>
                    </div>
                    {/* preloader content end */}
                </div>
                {/* preloader end */}
                {/* Hello */}
            </div>
            {/* app end */}
            <div id="swupMenu"></div>
        </div>
    </>)
}

export default HomePage;